import { Fragment, } from "react";
//import CenterLogo from "../../Elements/CenterLogo/CenterLogo";
import Card from "../../Elements/Card/Card";
import classes from "./NoConectado.module.css";

import {Animated} from "react-animated-css";
import ReactPlayer from "react-player";
import { Container } from "react-bootstrap";
import { getRandomLoader } from "../../../Services/Loader/LoaderFunctions";
import SmartContractsView from "../../Elements/SmartContractsView/SmartContractsView";
import CountdownSale from "../../Elements/Countdown/CountdownSale";

const NoConectado = props => {

    return (
        <Fragment>
            <Container fluid>
                <Card className = {classes.info} >

                    <Animated animationIn="bounceInDown" animationOut="fadeOut" isVisible={true}>
                        <h3 className="display-5 fw-bolder" align="center"><b>WELCOME TO LEIRA!</b></h3>
                    </Animated>

                    <CountdownSale/>

                    <div className={classes.divVideoInfo}>
                        <ReactPlayer
                            url={getRandomLoader()}
                            playing
                            muted
                            loop
                            width='100%'
                            height='100%'
                        />
                    </div>

                    <Animated animationIn="flash" animationOut="fadeOut" isVisible={true} animationInDelay={3000}>
                        <b align="center">Connect your wallet to start</b><br/><br/>
                    </Animated>
                </Card>
            </Container>
            
            <SmartContractsView/>
        </Fragment>
    );

}
export default NoConectado;