import classes from "./Header.module.css"
import Login from "../General/Login/Login";
import { Container, Navbar, Nav } from "react-bootstrap";

const Header = (props) =>{
    
    return (
        <header>
            <Navbar className={`${classes.navBackground}`} collapseOnSelect scrolling="true" dark="true" variant="dark" expand="lg" fixed="top">
                <Container fluid className="m-3">
                    <Navbar.Brand href="/">
                        <img
                            src="images/LogoDE.png"
                            width="140"
                            height="50"
                            className="d-inline-block align-top"
                            alt="Dark Earth logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Collapse className="text-center" id="responsive-navbar-nav">
                        
                        <Nav className="justify-content-end flex-grow-1">
                            <Login
                                infoAccount = {props.infoAccount}
                                onLogin={props.onLogin}
                                onLogout={props.onLogout}
                                chainId = { props.chainId }
                            />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;