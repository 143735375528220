import { Fragment, useState } from "react";
import NoConectado from "./NoConectado/NoConectado";
import Warning from "./Conectado/Warning/Warning";
import AdminArea from "../../../pages/AdminArea/AdminArea";

const Main = props => {

    const { isConnected, roles } = props.infoAccount;
    const [ isLoged, setIsLoged ] = useState(false);

    const database = [{
        username: "DEAdministratorDE",
        password: "&%th^@Lh&fEYb#Tluy88Z0Rp%a0snTvr"
    }]

    const handleSubmit = (event) => {
        event.preventDefault();
      
        var { uname, pass } = document.forms[0];
      
        const userData = database.find((user) => user.username === uname.value);
      
        if (userData) {
          if (userData.password === pass.value) {
            setIsLoged(true);
          }
        }
    };

    return (
        <Fragment>
            <main>
                { (isConnected && (roles.isOwner || roles.canWhitelist || roles.isAdmin || roles.isMinter)) ?
                    <Fragment>
                        {!props.canConnectToContract && <Warning/>}

                        { isLoged ? 
                            <AdminArea
                                canConnectToContract = { props.canConnectToContract }
                                infoAccount = { props.infoAccount }
                                web3Api = { props.web3Api }
                            />
                        :
                            <Fragment>
                                <div className="w-50 bg-dark mx-auto text-center fw-bolder">
                                    <div className="form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-container">
                                                <label className="display-4 text-white fw-bold">Username </label>
                                                <input type="text" name="uname" required className="w-50 mx-auto m-3"/>
                                            </div>
                                            <div className="input-container">
                                                <label className="display-4 text-white fw-bold">Password </label>
                                                <input type="password" name="pass" required className="w-50 mx-auto m-3"/>
                                            </div>
                                            <div className="button-container">
                                                <input type="submit" className="m-4"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                :
                    <NoConectado/>
                }
            </main>
        </Fragment>
    );

}
export default Main;