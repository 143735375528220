//import { useState } from "react";
import { Modal } from "react-bootstrap";
//import classes from "./Warning.module.css";

const Warning = props => {

    const show = true;

    function AlertModal(props) {
      return (
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="bg-danger">
            <Modal.Title className="display-8 fw-bolder" id="contained-modal-title-vcenter">
              YOU HAVE BEEN DISCONNECTED FROM POLYGON
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-danger">
            <h4 className="display-8 fw-bold">Please change your network.</h4>
            <p>
              If you are not on <b>Polygon</b> you will not be able to interact with the website.
            </p>
          </Modal.Body>
        </Modal>
      );
    }

    return (
        AlertModal()
      );
}

export default Warning;