import React, { Fragment } from "react";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import Admin from "../../components/UI/pages/Admin/Admin";
import Err404 from "../../components/UI/pages/Error404/Error404";

const AdminArea = (props) => {

    const { isConnected, roles } = props.infoAccount;

    return (
      <Fragment>
        <main>

            { !isConnected ?

                <NoConectado/>

                :
                (roles.isAdmin || roles.isOwner) ?

                <Admin
                  canConnectToContract = { props.canConnectToContract }
                  infoAccount = { props.infoAccount }
                  web3Api = { props.web3Api }
                />

                :

                <Err404/>
            }

        </main>
      </Fragment>
  );

}

export default AdminArea;