import { Fragment } from "react";

import classes from "./Error404.module.css";

import Card from "../../Elements/Card/Card";
import CenterLogo from "../../Elements/CenterLogo/CenterLogo";

const Err404 = (props) => {
    
    return (
        <Fragment>
            <CenterLogo/>
            <div className={classes.centrador}>
                <Card className={classes.infoCapsules}>
                    <img alt="Error 404" className={classes.imgError} src="images/404.png"/>
                    <h1 className="display-1 fw-bolder text-white">PAGE NOT FOUND</h1>
                </Card>
            </div>
        </Fragment>
    );

}
export default Err404;