import contract from "@truffle/contract";

export const loadContract = async (name, provider) => {
    const res = await fetch(`/contracts/${name}.json`)
    const Artifact = await res.json()

    const _MysteryCapsule = contract(Artifact)
    _MysteryCapsule.setProvider(provider)

    let deployedContract = null
    
    try {
        deployedContract = await _MysteryCapsule.deployed()
    } catch {
        console.error("Connected to the wrong network")
    }
    
    return deployedContract
}