import { useState, useEffect, Fragment } from "react";

// COMPONENTES PRINCIPALES
import Header from "./components/UI/Header/Header";
import Main from "./components/UI/Main/Main";
import Footer from "./components/UI/Footer/Footer";

// WEB3 - METAMASK
import Web3 from "web3";
import { loadContract } from "./utils/Contracts/load-contract";

import Loader from "./components/UI/General/Loader/Loader";

import { GlobalVariables, setGlobalVariables } from "./utils/GlobalVariables";
import { GlobalInfoAccount, setGlobalInfoAccount } from "./utils/GlobalInfoAccount";
//import { fastLoadingRun } from "./utils/FastLoading/FastLoading";

// SISTEMA DE PÁGINAS
import {
  Routes,
  Route,
} from "react-router-dom";

// PAGINAS
import Error404 from "./pages/404/Error404";

import ConstantesAPI from "./utils/API/Constantes";
import { changeNetwork, reloadDetails, resetInfoAccount } from "./utils/GlobalFunctions";

function App() {

  let web3Api = GlobalVariables;
  let globalInfoAccount = GlobalInfoAccount;

  const [ isLoadingWeb, setIsLoadingWeb ] = useState();

  // -------------------------
  // ALL IN ONE - ACCOUNT
  // -------------------------
  const cleanInfoAccount = resetInfoAccount();
  const [ infoAccount, setInfoAccount ] = useState(cleanInfoAccount);

  // Se puede conectar al Smart Contract?
  const [ canConnectToContract, setCanConnectToContract ] = useState(false)

  useEffect(() => {
    const updateGlobalInfoAccount = () => {
      setGlobalInfoAccount(infoAccount);
    }

    infoAccount && updateGlobalInfoAccount();
  }, [infoAccount, globalInfoAccount])

  // -----------------------------------------------------------
  // FUNCION GENERAL PARA ACTUALIZAR LA INFORMACION DE LA CUENTA
  // -----------------------------------------------------------
  // COMPATIBILIDAD CON ANTIGUAS FUNCIONES
  // Carga rápida - No es una carga completa.

  // Carga lenta - Es una carga completa
  const getBlockchainData = async (provider, web3, showLoader = true) => {
    if(provider && web3) {
      if(showLoader) setIsLoadingWeb(true);
      const accountsChain = await web3.eth.getAccounts();

      if(accountsChain.length === 0) {
          console.log("ERROR: No account detected!");
          const vacio = resetInfoAccount();
          setInfoAccount(vacio);
        } else {

          const chainId = await web3.eth.getChainId();

          if(chainId === 137 || chainId === 80001) {
            // Cargamos el contrato cuando estamos en la ChainID correcta
            const USDC = await loadContract("USDC", provider);
            const Collection = await loadContract("DECollection", provider);
            const MysteryCapsule = await loadContract("MysteryCapsule", provider);

            setCanConnectToContract(true);

            const newVars = {
              provider: provider,
              isProviderLoaded: true,
              web3: web3,
              networkId: chainId,
              contracts: {
                MysteryCapsule: MysteryCapsule,
                USDC: USDC,
                DECollection: Collection
              }
            }

            // -------------------
            // Información sobre WEB3
            setGlobalVariables(newVars);
            // -------------------
            // Informacion sobre el usuario
            const infoAccount = await reloadDetails(newVars.web3, newVars.contracts, accountsChain[0]);
            setGlobalInfoAccount(infoAccount);
            setInfoAccount(infoAccount);
            // -------------------
            //loadWalletsDataFromDB();
            // -------------------
            // Inicializar la API
            ConstantesAPI();
            // -------------------
            // Ejecutar consenso de TXs
            //await consensusRunNow(infoAccount, newVars.contracts);
          } else {
            console.log("ERROR: This is not a valid chain.");
            setCanConnectToContract(false);
            changeNetwork(web3);
          }

        }

        if(showLoader) setIsLoadingWeb(false);
    }
  }
  // -----------------------------------------------------------
  // Función asincrona para hacer login
  const onLogin = async (provider) => {
    if(provider){
      const web3 = new Web3(provider);
      
      if (ConstantesAPI === null) {
        await changeNetwork(web3);
      } else {
        await getBlockchainData(provider, web3);
      }

    } else {
      console.error("No se detecta provider");
    }
  }

  // Función para cuando se desconecta
  const onLogout = () => {
    const vacio = resetInfoAccount();
    setInfoAccount(vacio);
  }

  // Cambios interactivos
  useEffect(() => {

    // Manejador para tratar cuando se cambia de cuenta
    const handleAccountChanged = async (accounts) => {
      await getBlockchainData(web3Api.provider, web3Api.web3);
    }

    // Manejador para tratar cuando se cambia de red
    const handleChainChanged = async () => {
      await getBlockchainData(web3Api.provider, web3Api.web3);
    }

    // Nos subscribimos a los eventos
    if(infoAccount.isConnected) {
      const { provider } = web3Api;
      provider.on("accountsChanged", handleAccountChanged);
      provider.on("chainChanged", handleChainChanged);
      
    }

    return () => {
      if(infoAccount.isConnected) {
        const { provider } = web3Api;
        provider.removeListener("accountsChanged", handleAccountChanged);
        provider.removeListener("chainChanged", handleChainChanged);
      }
      
    }

  }, [infoAccount, web3Api])

  // -----------------------------------------------------------

  return (
  <>
      {isLoadingWeb ? 
        <Fragment>
          <Loader/>
        </Fragment>
      :
        <Fragment>
          <Header
            infoAccount = { infoAccount }
            onLogin = { onLogin }
            onLogout = { onLogout }
            chainId = { web3Api.networkId }
          />

          <Routes>
            <Route path="/" element={
              <Main
                canConnectToContract = { canConnectToContract }
                infoAccount = { infoAccount }
                web3Api = { web3Api }
              />
            }/>

            <Route path='*' exact={true} element={
              <Error404
                isConnected = { infoAccount.isConnected }
              />
            }/>
            
          </Routes>

          <Footer/>
        </Fragment>
      }
  </>
  );
}

export default App;
