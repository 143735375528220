import { Container } from "react-bootstrap";
import Card from "../Card/Card";

const SmartContractsView = () => {
    return (
        <Container fluid className="bg-black mx-auto text-center w-75 pt-4">
            <p className="text-white display-6 pt-4">Our Smart Contracts</p>
            <div className="w-50 d-inline-block p-2">
                <Card>
                    <p className="text-white text-center p-2">
                        <strong>Genesis Mystery Capsules</strong> (ERC721)<br/>
                        <code className="bg-light text-black rounded p-1">0xcF06A29C07a1CD8f232C9Ad3e3FFcF1feDA4cee5</code>
                    </p>

                    <div className="w-75 mx-auto">
                        <code className="text-white">
                            Mystery Capsules by Dark Earth are special NFTs that gives 8 random NFTs from Genesis Edition when are opened.
                        </code>
                    </div>

                    <div className="m-2">
                        <a href="https://github.com/solidproof/projects/tree/main/DarkEarth" target="_blank" rel="noreferrer"><img src="images/audit/badge.png" alt="Badge audited"/></a>
                    </div>

                    <a href="https://polygonscan.com/address/0xcF06A29C07a1CD8f232C9Ad3e3FFcF1feDA4cee5" target="_blank" rel="noreferrer" className="mx-2">
                        <button type="button" className="btn btn-outline-light text-center mx-auto m-3">
                            <span className="m-2">PolygonScan</span>
                        </button>
                    </a>
                    <a href="https://opensea.io/collection/mystery-capsules" target="_blank" rel="noreferrer" className="mx-2">
                        <button type="button" className="btn btn-outline-light text-center mx-auto m-3">
                            <span className="m-2">Opensea</span>
                        </button>
                    </a>
                </Card>
            </div>

            <div className="w-50 d-inline-block p-2">
                <Card>
                    <p className="text-white text-center p-2">
                        <strong>Dark Earth Collection</strong> (ERC721)<br/>
                        <code className="bg-light text-black rounded p-1">0xF57F3A0b3415cC2daE6b3A3c4Fd3FC58626bE16E</code>
                    </p>

                    <div className="w-75 mx-auto">
                        <code className="text-white">
                            All these NFTs will be playable in the Games of the ecosystem Dark Earth. These NFTs can be collected for special rewards.
                        </code>
                    </div>

                    <div className="m-2">
                        <a href="https://github.com/solidproof/projects/tree/main/DarkEarth" target="_blank" rel="noreferrer"><img src="images/audit/badge.png" alt="Badge audited"/></a>
                    </div>

                    <a href="https://polygonscan.com/address/0xF57F3A0b3415cC2daE6b3A3c4Fd3FC58626bE16E" target="_blank" rel="noreferrer" className="mx-2">
                        <button type="button" className="btn btn-outline-light text-center mx-auto m-3">
                            <span className="m-2">PolygonScan</span>
                        </button>
                    </a>
                    <a href="https://opensea.io/collection/dark-earth-collection" target="_blank" rel="noreferrer" className="mx-2">
                        <button type="button" className="btn btn-outline-light text-center mx-auto m-3">
                            <span className="m-2">Opensea</span>
                        </button>
                    </a>
                </Card>
            </div>

        </Container>
    );
}

export default SmartContractsView;