import classes from "./Footer.module.css";

import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";

const Footer = () => {

    const version = "Version: " + process.env.REACT_APP_GIT_SHA;

    return (
        <Navbar className={`${classes.fooBackground}`} collapseOnSelect scrolling="true" dark="true" variant="dark" expand="lg">
        <Container fluid className="m-3">
            <Row className="w-100">
                <Navbar.Collapse className="text-center" id="responsive-navbar-nav">
                    <Col>
                        <Nav.Link className="d-flex justify-content-start text-warning" href="https://github.com/solidproof/projects/tree/main/DarkEarth">Audited by SOLIDProof</Nav.Link>
                    </Col>
                    <Col>
                        <Row>
                            <Nav className="justify-content-center flex-grow-1 pe-3" navbarScroll>
                                <Nav.Link href="https://www.darkearth.gg" target="_blank">Website</Nav.Link>
                                <Nav.Link href="https://presale.darkearth.gg" target="_blank">Presale</Nav.Link>
                                <Nav.Link href="https://litepaper.darkearth.gg" target="_blank">Litepaper</Nav.Link>
                            </Nav>
                        </Row>
                        <Row className="justify-content-center">
                            <small className="text-muted">{version}</small>
                        </Row>
                    </Col>
                    <Col>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Navbar.Brand href="https://twitter.com/DarkEarthgame" target="_blank">
                                <img
                                    src="images/rrss/twitter.png"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-right"
                                    alt="Twitter"
                                />
                            </Navbar.Brand>
                            <Navbar.Brand href="https://discord.darkearth.gg" target="_blank">
                                <img
                                    src="images/rrss/discord.png"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-right"
                                    alt="Discord"
                                />
                            </Navbar.Brand>
                            <Navbar.Brand href="https://t.me/darkearthgame" target="_blank">
                                <img
                                    src="images/rrss/telegram.png"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-right"
                                    alt="Telegram"
                                />
                            </Navbar.Brand>
                        </Nav>
                    </Col>
                </Navbar.Collapse>
            </Row>
        </Container>
        </Navbar>
    );
}

export default Footer;