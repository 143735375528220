import { useState, Fragment } from "react";
import DropdownWallet from "../../Elements/Buttons/HeaderWallet/DropdownWallet";

// MetaMask -> Autodetectar wallet
import Web3Modal from "web3modal";
import { sequence } from '0xsequence';
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const Login = props => {

    const [ isConnecting, setIsConneting ] = useState(false);
    const { account, balance, balanceUSDC, isConnected, collectionDetails } = props.infoAccount;

    const NETWORK_ID = 137;
    const NETWORK_NAME = "polygon";
    const NETWORK_NAME2 = "matic";
    const NETWORK_RPC = "https://polygon-rpc.com";
    const INFURA_ID = "62559bba8bef40748d7823c29b2ea045";
    
    // ------------------
    
    const providerOptions = {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                rpc: {
                    137: NETWORK_RPC
                },
                infuraId: INFURA_ID,
                network: NETWORK_NAME2,
                supportedNetworks: [NETWORK_ID],
                chainId: NETWORK_ID
            }
        },
        sequence: {
            package: sequence, // required
            options: {
                appName: "Dark Earth", // optional
                defaultNetwork: NETWORK_NAME // optional
            }
        },
        coinbasewallet: {
            package: CoinbaseWalletSDK, // Required
            options: {
              appName: "Dark Earth", // Required
              infuraId: INFURA_ID, // Required
              rpc: {
                NETWORK_ID: NETWORK_RPC
              },
              chainId: NETWORK_ID, // Optional. It defaults to 1 if not provided
              darkMode: true // Optional. Use dark theme, defaults to false
            }
        },
        injected: {
            display: {
                description: "Connect with your wallet in your Browser"
            },
        }
    };

    const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required,
        theme: {
            background: "rgb(20, 20, 20)",
            main: "rgb(255, 255, 255)",
            secondary: "rgb(136, 136, 136)",
            border: "rgba(195, 195, 195, 0.14)",
            hover: "rgb(40, 40, 40)"
          }
    });
    
      
    // ------------------

    const useLogin = async () => {
        const loadProvider = async () => {

            web3Modal.clearCachedProvider();

            try {
                const provider = await web3Modal.connect();
                if(provider.wc) {
                    provider.disconnect();
                }
                await onLoginHandler(provider);
            } catch(error) {
                console.log("LOGIN ERROR -> ", error);
                //window.location.reload();
            }

            
        }
        
        await loadProvider();
    }

    const onLoginHandler = async (auxProv) => {
        
        setIsConneting(true);
        if(auxProv.isMetamask){
            await auxProv.request({
                method: "eth_requestAccounts"
            }).catch((error) => {
                console.log("ERROR MetaMask: ", error);
                //window.location.reload();
            });
        }
        setIsConneting(false);
        props.onLogin(auxProv);
    };

    const onLogoutHandler = async () => {
        web3Modal.clearCachedProvider();
        props.onLogout();
    };

    return (
        <Fragment>
                <Fragment>
                    { !isConnected ?
                        
                        <button type="button" className="btn btn-outline-light" onClick = { useLogin } disabled={isConnecting}>
                            <b className="mx-2">{ !isConnecting ? "CONNECT WALLET" : "CONNECTING..."}</b>
                        </button>

                        :

                        <DropdownWallet
                            onLogout = { onLogoutHandler }
                            balance = { balance }
                            balanceUSDC = { balanceUSDC }
                            currentAccount = { account }
                            chainId = { props.chainId }
                            tokenIds = { collectionDetails.cardsIds }
                        />
                    }

                </Fragment>
        </Fragment>
    );
}

export default Login;