import React, { Fragment } from "react";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import Err404 from "../../components/UI/pages/Error404/Error404";

const Error404 = (props) => {
    return (
      <Fragment>
        <main>

            { !props.isConnected ?

                <NoConectado/>
                :
                <Err404/>
            }

        </main>
      </Fragment>
  );

}

export default Error404;
