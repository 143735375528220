import { Fragment, useState, useEffect } from "react";

import classes from "./Admin.module.css";

import Card from "../../Elements/Card/Card";

import { Card as CardB } from "react-bootstrap";
import Swal from "sweetalert2";
// Importar CSV
//import { FilePicker } from 'react-file-picker'
// Ver historial de transacciones
import { JsonToTable } from "react-json-to-table";
import ConstantesAPI from "../../../../utils/API/Constantes";

const Admin = (props) => {

    // Recibido por parametros
    const { account, roles, mintDetails } = props.infoAccount;
    const { isPublicSale } = mintDetails;
    const { contracts, web3 } = props.web3Api;
    const { MysteryCapsule, DECollection } = contracts;

    // Wallet introducida en la casilla
    const [ walletUser, setWalletUser ] = useState(null);

    // Bulk Wallet introducida en la casilla
    const [ bulkWalletUser, setBulkWalletUser ] = useState(null);

    // Cantidad introducida en la casilla
    const [ cantityInput, setCantityImput ] = useState(null);

    const [ bulkCantityInput, setBulkCantityImput ] = useState(null);

    // Rol en el selector
    const [ rolInput, setRolInput ] = useState(null);

    // Comprobar el estado del contrato
    const [ MysteryCapsuleDisabled, setContractDisabled ] = useState(null);

    // Comprobar el de la funcion WL
    const [ functionWhitelist, setFunctionWhitelist ] = useState(null);

    // Cargar balance Smart Contract
    const [ balanceSC, setBalanceSC ] = useState(0)
    const [ balanceUsdSC, setBalanceUsdSC ] = useState(0)

    // Limite de capsulas por wallet
    const [ defaultMintAmount, setDefMintAmount ] = useState(0);

    // Todas las transacciones
    const [ allTransactions, setAllTransactions ] = useState();

    // Todas las transacciones de admin
    const [ allAdminTransactions, setAllAdminTransactions ] = useState();

    const [ seeOptions, setSeeOptions ] = useState(0);

    // --------------------------
    // MENU DE OPCIONES ADMIN
    // --------------------------

    const handleOptions = (toggle) => {
        setSeeOptions(toggle);
    }

    // --------------------------
    // OBTENER WALLET DEL INPUT
    // --------------------------
    const actualizaWallet = (event) => {
        const wallet = event.target.value;
        setWalletUser(wallet.toString());
    };

    const actualizaBulkWallet = (event) => {
        const wallet = event.target.value;
        const aux = wallet.replace(/\r\n/g,"\n").split("\n");
        setBulkWalletUser(aux);
    };

    // --------------------------
    // OBTENER CANTIDAD DEL INPUT
    // --------------------------
    const actualizaCantidad = (event) => {
        const cantidad = event.target.value;
        setCantityImput(cantidad.toString());
    };

    const actualizaBulkCantidad = (event) => {
        const cantidad = event.target.value;
        const aux = cantidad.replace(/\r\n/g,"\n").split("\n");
        setBulkCantityImput(aux);
    };
    
    // --------------------------
    // OBTENER CANTIDAD DEL INPUT
    // --------------------------
    const actualizaRol = (event) => {
        const rol = event.target.value;
        setRolInput(rol);
    };

    // GET ALL USER TRANSACTIONS
    const getTransactions = async () => {
        const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/obtenerAll_txs.php`);
        const transTx = await respuesta.json();
        setAllTransactions(transTx);
    }

    // GET ALL ADMIN TRANSACTIONS
    const getAdminTransactions = async () => {
        const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/obtenerAll_AdminTxs.php`);
        const transTx = await respuesta.json();
        setAllAdminTransactions(transTx);
    }

    // ----------------------------
    // ACTIVAR EDICIONES DE CARTAS
    // ----------------------------

    // SUPPLY DE LA COLECCIÓN
    const getSupplyData = async () => {
        const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/obtener_Supplys.php`);
        const transTx = await respuesta.json();
        return transTx;
      }

    const addSupplyOnSC = async () => {
        // Añadimos el Supply inicial
        const datos = await getSupplyData();

        //var idGenerales = [];
        var idNfts      = [];
        var supplys     = [];

        // -------------------

        for(var i = 0; i < datos.length; i++){
            //idGenerales.push(datos[i].idGeneral);
            idNfts.push(datos[i].idNft);
            supplys.push(datos[i].supply);
        }

        // ----------------
        // Hacer llamada para añadir el Supply
        var Ok = false;
        if(idNfts.length !== 0) {
            try {
                await DECollection.addBulkSupply.call(idNfts, supplys, {
                    from: account
                }).catch((error) => {
                    errorAlert(error.message);
                });
                Ok = true;
            } catch(e) {
                errorAlert(e.message);
            }

            if(Ok) {
                await DECollection.addBulkSupply(idNfts, supplys, {
                    from: account
                }).then((r) => {
                    // PETICION A LA API PARA QUE LAS MARQUE COMO SUBIDAS
                    saveUsedSupply();
                }).catch((error) => {
                    errorAlert(error.message);
                });
            }
        } else {
            errorAlert("No hay nada para subir en base de datos.");
        }
    }

    // --------------------------
    // OBTENER DATOS DEL SC
    // --------------------------
    // Get cantidad máxima para mintear por persona (por defecto)
    useEffect(() => {
        const loadDefaultMintAmount = async () => {
            const _defaultMintAmount = await MysteryCapsule.getDefaultMintAmount();
            setDefMintAmount(_defaultMintAmount.toString());
        }

        MysteryCapsule && loadDefaultMintAmount()
    }, [MysteryCapsule, setDefMintAmount])

    useEffect(() => {
        const loadContratState = async () => {
            const _isSuspend = await MysteryCapsule.isSuspend();
            const _isWLFunc = await MysteryCapsule.isSuspendWL();
            setContractDisabled(_isSuspend);
            setFunctionWhitelist(_isWLFunc);
        }

        MysteryCapsule && loadContratState()
    }, [MysteryCapsule, setContractDisabled])

    useEffect(() => {
        const loadBalanceSC = async () => {
            const _balance = await web3.eth.getBalance(MysteryCapsule.address);
            const _balanceWei = await web3.utils.fromWei(_balance, 'ether');
            setBalanceSC(_balanceWei);
        }

        MysteryCapsule && loadBalanceSC()
    }, [MysteryCapsule, web3, setBalanceSC])

    useEffect(() => {
        const loadBalanceUsdcSC = async () => {
            const _balance = await MysteryCapsule.GetUsdcBalance();
            const _balanceWei = await web3.utils.fromWei(_balance, 'Mwei');
            setBalanceUsdSC(_balanceWei);
        }

        MysteryCapsule && loadBalanceUsdcSC()
    }, [MysteryCapsule, web3, setBalanceUsdSC])

    // -------------------------------------
    // ADD ROLE TO WALLET
    // -------------------------------------
    const addRoleRole = () => {
        const daleRol = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;
            if(rolInput === "Admin"){
                const ADMIN_ROLE = web3.utils.soliditySha3("ADMIN_ROLE");
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                try {
                    
                    await MysteryCapsule.addRole.call(walletUser.toString(), ADMIN_ROLE, true, {
                        from: account
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }

                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.addRole(walletUser.toString(), ADMIN_ROLE, true, {
                        from: account
                    }).then((result) => {
                        manejarEnvioTransaccion(account, result.tx, "AddRole_Admin", walletUser.toString());
                        successAlert(result.tx);
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            } else if(rolInput === "Minter"){
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                const MINTER_ROLE = web3.utils.soliditySha3("MINTER_ROLE");
                try {
                    await MysteryCapsule.addRole.call(walletUser.toString(), MINTER_ROLE, true, {
                        from: account
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }

                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.addRole(walletUser.toString(), MINTER_ROLE, true, {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "AddRole_Minter", walletUser.toString());
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            } else if(rolInput === "canWhitelist"){
                const WHITELIST_ROLE = web3.utils.soliditySha3("WHITELIST_ROLE");
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                try {
                    await MysteryCapsule.addRole.call(walletUser.toString(), WHITELIST_ROLE, true, {
                        from: account
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }
    
                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.addRole(walletUser.toString(), WHITELIST_ROLE, true, {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "AddRole_Withdraw", walletUser.toString());
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            } else if(rolInput === "Burner"){
                const BURNER_ROLE = web3.utils.soliditySha3("BURNER_ROLE");
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                try {
                    await MysteryCapsule.addRole.call(walletUser.toString(), BURNER_ROLE, true, {
                        from: account
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }
    
                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.addRole(walletUser.toString(), BURNER_ROLE, true, {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "AddRole_BURNER_ROLE", walletUser.toString());
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            }
        }

        MysteryCapsule && daleRol()
    }

    
    const delRole = () => {
        const delRol = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;
            if(rolInput === "Admin"){
                const ADMIN_ROLE = web3.utils.soliditySha3("ADMIN_ROLE");
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                try {
                    await MysteryCapsule.addRole.call(walletUser.toString(), ADMIN_ROLE, false, {
                        from: account
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }

                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.addRole(walletUser.toString(), ADMIN_ROLE, false, {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "RevokeRole_Admin", walletUser.toString());
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            } else if(rolInput === "Minter"){
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                const MINTER_ROLE = web3.utils.soliditySha3("MINTER_ROLE");
                try {
                    await MysteryCapsule.addRole.call(walletUser.toString(), MINTER_ROLE, false, {
                        from: account
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }

                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.addRole(walletUser.toString(), MINTER_ROLE, false, {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "RevokeRole_Minter", walletUser.toString());
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            } else if(rolInput === "Withdrawer"){
                const WITHDRAW_ROLE = web3.utils.soliditySha3("WITHDRAW_ROLE");
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                try {
                    await MysteryCapsule.addRole.call(walletUser.toString(), WITHDRAW_ROLE, false, {
                        from: account
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }
    
                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.addRole(walletUser.toString(), WITHDRAW_ROLE, false, {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "RevokeRole_Withdraw", walletUser.toString());
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            }
        }

        MysteryCapsule && delRol()
    }

    const handleFreeMint = async () => {

        const addFreeMint = async () => {
            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.bulkAddFreeMint.call(bulkWalletUser, bulkCantityInput, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.bulkAddFreeMint(bulkWalletUser, bulkCantityInput, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "AddFreeMint", bulkWalletUser.toString() + " -- " + bulkCantityInput.toString() );
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }
        }

        MysteryCapsule && addFreeMint()
    }

    const handleAddFreeMintBD = async () => {

        const addFreeMint = async () => {
            const datos = {
                wallet: walletUser,
                idCard: cantityInput
            }
        
            const cargaUtil = JSON.stringify(datos);
        
            // ¡Y enviarlo!
            const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/crea_FreeMint.php`, {
                method: "POST",
                body: cargaUtil,
            });

            const transTx = await respuesta.json();

            successAlert(transTx.toString());
        }

        cantityInput && walletUser && addFreeMint()
    }

    // -------------------------------------
    // ADD WHITELIST -> DEFAULT MINT AMOUNT
    // -------------------------------------
    const addWhitelist = () => {
        const daleWL = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.addToWhitelist.call(walletUser, defaultMintAmount, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.addToWhitelist(walletUser, defaultMintAmount, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "AddWL", walletUser.toString());
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && daleWL()
    }

    const addBulkWhitelist = () => {
        const daleBulkWL = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.bulkDefaultAddToWhitelist.call(bulkWalletUser, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.bulkDefaultAddToWhitelist(bulkWalletUser, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "BulkAddWL", bulkWalletUser.toString());
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && daleBulkWL()
    }

    // -------------------------------------
    // ADD MYSTERY CAPSULE
    // -------------------------------------
    const addMysteryCapsule = () => {
        const addMC = async () => {
            var cantidad = cantityInput;
            if(cantityInput === null){
                cantidad = 1;
            } else {
                cantidad = cantityInput;
            }
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.bulkAdminMint.call(walletUser, cantidad, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.bulkAdminMint(walletUser, cantidad, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "BulkMint", walletUser.toString() + " | Cantidad: " + cantidad.toString());
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && addMC()
    }

    // -------------------------------------
    // CHANGE MYSTERY CAPSULE POR WALLET
    // -------------------------------------
    const changeMCporWallet = () => {
        const changeMCWallet = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.setDefaultMintAmount.call(cantityInput, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.setDefaultMintAmount(cantityInput, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "Change default mint amount - Wallet", cantityInput.toString());
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && changeMCWallet()
    }

    // -------------------------------------
    // CHANGE MYSTERY CAPSULE TOTAL
    // -------------------------------------
    const changeMCporTotal = () => {
        const changeMCTotal = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.setLimitChest.call(cantityInput, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.setLimitChest(cantityInput, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "Change default mint amount - TOTAL", cantityInput.toString());
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && changeMCTotal()
    }

    // -------------------------------------
    // CHANGE MYSTERY CAPSULE PRICE
    // -------------------------------------
    const changeMCPrice = () => {
        const changeMCP = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;
            const nuevoPrecio = cantityInput; // Compatibilidad para anterior cambio en MATIC
            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.setDefaultPrice.call(nuevoPrecio.toString(), {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.setDefaultPrice(nuevoPrecio, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "Change price mint", cantityInput.toString() + " MATIC");
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && changeMCP()
    }

    // -------------------------------------
    // RETIRAR FONDOS
    // -------------------------------------
    const withdrawContract = () => {
        const WdContract = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;
            const cantidadRetirar = await web3.utils.toWei(cantityInput, 'ether');
            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.withdraw.call(cantidadRetirar, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.withdraw(cantidadRetirar, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "WITHDRAW", cantityInput.toString() + " MATIC");
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && WdContract()
    }

    const withdrawUsdcContract = () => {
        const WdUsdcContract = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var peticionOK = false;
            const cantidadRetirar = await web3.utils.toWei(cantityInput, 'Mwei');
            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.withdrawUSDC.call(cantidadRetirar, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.withdrawUSDC(cantidadRetirar, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "WITHDRAW", cantityInput.toString() + " USDC");
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && WdUsdcContract()
    }

    const searchRoles = () => {
        const sRoles = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();
            var tieneAdmin = false;
            var tieneMinter = false;
            var tieneWithdraw = false;
            var tieneWhitelist = false;

            // Compruebo roles
            const ADMIN_ROLE = web3.utils.soliditySha3("ADMIN_ROLE");
            await MysteryCapsule.hasRole.call(ADMIN_ROLE, walletUser, {
                from: account
            }).then((result) => {
                tieneAdmin = result;
            }).catch((e) => {
                 errorAlert(e.message);
            })

            // ------------
            const MINTER_ROLE = web3.utils.soliditySha3("MINTER_ROLE");
            await MysteryCapsule.hasRole.call(MINTER_ROLE, walletUser, {
                from: account
            }).then((result) => {
                tieneMinter = result;
            }).catch((e) => {
                 errorAlert(e.message);
            })
            // ------------
            const WITHDRAW_ROLE = web3.utils.soliditySha3("WITHDRAW_ROLE");
            await MysteryCapsule.hasRole.call(WITHDRAW_ROLE, walletUser, {
                from: account
            }).then((result) => {
                tieneWithdraw = result;
            }).catch((e) => {
                 errorAlert(e.message);
            })
            // ------------
            const WHITELIST_ROLE = web3.utils.soliditySha3("WHITELIST_ROLE");
            await MysteryCapsule.hasRole.call(WHITELIST_ROLE, walletUser, {
                from: account
            }).then((result) => {
                tieneWhitelist = result;
            }).catch((e) => {
                errorAlert(e.message);
            })


            roleMes(tieneAdmin, tieneMinter, tieneWithdraw, tieneWhitelist);

        }

        MysteryCapsule && sRoles()
    }

    // ----------------------------------------
    // ACTIVAR Y DESACTIVAR FUNCIONES DEL SC
    // ----------------------------------------
    const disableAllSC = () => {
        const suspendSC = async () => {

            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.toggleSuspend.call(!MysteryCapsuleDisabled, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.toggleSuspend(!MysteryCapsuleDisabled, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "SUSPEND SC", "Enable/Disable SC Mystery Capsules");
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && suspendSC()
    }

    const disableAllWL = () => {
        const suspendWL = async () => {

            // ------------------------
            // Mensaje de información -> Transacción iniciada

            infoAlert();
            var peticionOK = false;

            // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
            try {
                await MysteryCapsule.toggleSuspendWL.call(!functionWhitelist, {
                    from: account
                })
                peticionOK = true;
            } catch(error){
                errorAlert(error.message);
            }

            // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
            if(peticionOK){
                await MysteryCapsule.toggleSuspendWL(!functionWhitelist, {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "SUSPEND WL", "Enable/Disable whitelist Mystery Capsules");
                }).catch((e) => {
                    errorAlert(e.message);
                })
            }

        }

        MysteryCapsule && suspendWL()
    }

    const disablePresale = () => {
        const suspendPresale = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();

            var todoOk = false;

            if(isPublicSale) {
                try {
                    await MysteryCapsule.suspendPublicSale.call({
                        from: account
                    }).then((result) => {
                        todoOk = true;
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                } catch(error) {
                    errorAlert(error.message);
                }

                if(todoOk) {
                    await MysteryCapsule.suspendPublicSale({
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "DISABLE PUBLIC SALE", "Activate private sale. Only whitelisted");
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            } else {
                try {
                    await MysteryCapsule.enablePublicSale.call({
                        from: account
                    }).then((result) => {
                        todoOk = true;
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                } catch(error) {
                    errorAlert(error.message);
                }

                if(todoOk) {
                    await MysteryCapsule.enablePublicSale({
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "ENABLE PUBLIC SALE", "Public sale its enable now. Price = 20");
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }
            }

        }

        MysteryCapsule && suspendPresale()
    }

    const handleAddApprove = (isMysteryCap) => {
        const addApprove = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();

            var todoOk = false;

            if(isMysteryCap) {
                
                try {
                    await MysteryCapsule.approveOwner.call(cantityInput, walletUser.toString(), {
                        from: account
                    }).then(() => {
                        todoOk = true;
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                } catch(error) {
                    errorAlert(error.message);
                }

                if(todoOk) {
                    await MysteryCapsule.approveOwner(cantityInput, walletUser.toString(), {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "APPROVE ON MC", "Wallet approved: " + walletUser + " | Function: " + cantityInput);
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }

            } else {

                try {
                    await DECollection.approveOwner.call(cantityInput, walletUser.toString(), {
                        from: account
                    }).then(() => {
                        todoOk = true;
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                } catch(error) {
                    errorAlert(error.message);
                }

                if(todoOk) {
                    await DECollection.approveOwner(cantityInput, walletUser.toString(), {
                        from: account
                    }).then((result) => {
                        successAlert(result.tx);
                        manejarEnvioTransaccion(account, result.tx, "APPROVE ON COLLECTION", "Wallet approved: " + walletUser + " | Function: " + cantityInput);
                    }).catch((e) => {
                        errorAlert(e.message);
                    })
                }

            }

        }

        MysteryCapsule && DECollection && addApprove()
    }

    const handleRemoveApprove = (isMysteryCap) => {
        const remApprove = async () => {
            // ------------------------
            // Mensaje de información -> Transacción iniciada
            infoAlert();

            if(isMysteryCap) {
                
                await MysteryCapsule.clearApprove(walletUser.toString(), {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "CLEAR ON MC", "Wallet cleaned: " + walletUser.toString());
                }).catch((e) => {
                    errorAlert(e.message);
                })

            } else {

                await DECollection.clearApprove(walletUser.toString(), {
                    from: account
                }).then((result) => {
                    successAlert(result.tx);
                    manejarEnvioTransaccion(account, result.tx, "CLEAR ON COLLECTION", "Wallet cleaned: " + walletUser.toString());
                }).catch((e) => {
                    errorAlert(e.message);
                })

            }

        }

        MysteryCapsule && DECollection && remApprove()
    }

    // -------------------------------------------------------
    // NOTIFICACIONES Y ALERTAS
    // -------------------------------------------------------
    // Alerta de confirmación -> Añadir rol
    const confirmRoleAlert = () => {
        if(!walletUser || !rolInput){
            errorAlert("La wallet no puede estar vacía o no has escogido un rol para asignar.");
        } else {
            if(rolInput === "Select role") {
                errorAlert("Selecciona un rol para asignar!");
            }else {
                Swal.fire({
                    title: "Confirma tu acción",
                    html: "Vas a darle " + rolInput + " a <b>" + walletUser + "</b><br/><br/>¿Estás de acuerdo?",
                    icon: "question",
                    showConfirmButton: true,
                    confirmButtonText: 'Yes!',
                    width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                    showCancelButton: true,
                    denyButtonText: 'Cancel',
                    footer: 'Accept the transaction in your wallet to continue.',
                }).then((result) => {
                    if(result.isConfirmed){
                        addRoleRole();
                    }
                });
            }
        }
    }
    const confirmDelRolAlert = () => {
        if(!walletUser || !rolInput){
            errorAlert("La wallet no puede estar vacía o no has escogido un rol para asignar.");
        } else {
            if(rolInput === "Select role") {
                errorAlert("Selecciona un rol para asignar!");
            }else {
                Swal.fire({
                    title: "Confirma tu acción",
                    html: "Vas a quitarle " + rolInput + " a <b>" + walletUser + "</b><br/><br/>¿Estás de acuerdo?",
                    icon: "question",
                    showConfirmButton: true,
                    confirmButtonText: 'Yes!',
                    width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                    showCancelButton: true,
                    denyButtonText: 'Cancel',
                    footer: 'Accept the transaction in your wallet to continue.',
                }).then((result) => {
                    if(result.isConfirmed){
                        delRole();
                    }
                });
            }
        }
    }
    
    // Alerta de confirmación -> Añadir WL
    const confirmWLAlert = () => {
        if(!walletUser){
            errorAlert("La wallet no puede estar vacía");
        } else {
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a darle WL a <b>" + walletUser + "</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                background: "#131312",
                color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    addWhitelist();
                }
            });
        }
    }

    const confirmBulkWLAlert = () => {
        if(!bulkWalletUser){
            errorAlert("No reconozco ninguna wallet");
        } else {
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a darle WL a <b>" + bulkWalletUser + "</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                background: "#131312",
                color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    addBulkWhitelist();
                }
            });
        }
    }

    // Alerta de confirmación -> Mintear Mystery Capsule
    const confirmMCAlert = () => {
        if(!walletUser){
            errorAlert("La wallet no puede estar vacía");
        } else{
            var cantidad = null;
            if(cantityInput === null){
                cantidad = 1;
            } else {
                cantidad = cantityInput;
            }
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a mintear <b>" + cantidad + " a " + walletUser + "</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                width: "50rem",
                background: "#131312",
                color: "#FFF",
                denyButtonText: 'Cancel',
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    addMysteryCapsule();
                }
            });
        }
    }

    // Alerta de confirmación -> Cambiar MC por Wallet
    const confirmMCWalletAlert = () => {
        if(!cantityInput){
            errorAlert("La cantidad no puede estar vacía");
        } else{
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a cambiar la cantidad máxima que un usuario puede mintear a <b>" + cantityInput + " Mystery Capsules</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    changeMCporWallet();
                }
            });
        }
    }

    // Alerta de confirmación -> Cambiar MC TOTAL
    const confirmMCTotalAlert = () => {
        if(!cantityInput){
            errorAlert("La cantidad no puede estar vacía");
        } else{
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a cambiar la cantidad máxima TOTAL a <b>" + cantityInput + " Mystery Capsules</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    changeMCporTotal();
                }
            });
        }
    }

     // Alerta de confirmación -> Cambiar precio Mystery Capsule
     const confirmMCPriceAlert = () => {
        if(!cantityInput){
            errorAlert("La cantidad no puede estar vacía");
        } else{
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a cambiar el precio por Mystery Caspule a <b>" + cantityInput + " DOLAR</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    changeMCPrice();
                }
            });
        }
    }

    // Alerta de confirmación -> Retirar fondos
    const confirmWithdrawAlert = () => {
        if(!cantityInput){
            errorAlert("La cantidad no puede estar vacía");
        } else{
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a retirar del Smart Contract a <b>" + cantityInput + " MATIC. <br/><br/>RECUERDA: IRÁN A TU WALLET.</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    withdrawContract();
                }
            });
        }
    }

    const confirmWithdrawUsdcAlert = () => {
        if(!cantityInput){
            errorAlert("La cantidad no puede estar vacía");
        } else{
            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a retirar del Smart Contract a <b>" + cantityInput + " USDC. <br/><br/>RECUERDA: IRÁN A TU WALLET.</b><br/><br/>¿Estás de acuerdo?",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    withdrawUsdcContract();
                }
            });
        }
    }

    const confirmSuspendEnable = () => {

            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a activar/desactivar funcionalidades del Smart Contract",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    disableAllSC();
                }
            });

    }

    const confirmPublicsaleEnable = () => {

            Swal.fire({
                title: "Confirma tu acción",
                html: "Vas a activar/desactivar el minteo publico. \n ¡¡<b>Ten cuidado con lo que haces.!!</b>",
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                footer: 'Accept the transaction in your wallet to continue.',
            }).then((result) => {
                if(result.isConfirmed){
                    disablePresale();
                }
            });

    }

    const confirmWhitelistEnable = () => {

        Swal.fire({
            title: "Confirma tu acción",
            html: "Vas a activar/desactivar añadir Whitelist. \n ¡¡<b>Ten cuidado con lo que haces.!!</b>",
            icon: "question",
            showConfirmButton: true,
            confirmButtonText: 'Yes!',
            showCancelButton: true,
            denyButtonText: 'Cancel',
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            footer: 'Accept the transaction in your wallet to continue.',
        }).then((result) => {
            if(result.isConfirmed){
                disableAllWL();
            }
        });

}

    // Request in process
    const infoAlert = () => {
        Swal.fire({
            title: "Request in process",
            text: "Accept the transaction in your wallet to continue.",
            icon: "info",
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    const freeMintAlert = (isBlockchain) => {
        var msg;
        if(isBlockchain) {
            msg = "Si continuas regalarás a <b>" + bulkWalletUser.toString() + "</b> minteados gratuitos de <b>Mystery capsules. Cantidades: " + bulkCantityInput.toString() + "</b><br/><br/> <b>¿Estás seguro?</b>"
        } else {
            msg = "Si continuas regalarás a <b>" + walletUser + "</b> un minteo gratuito del <b>NFT con ID " + cantityInput + "</b><br/><br/> <b>¿Estás seguro?</b>";
        }
        Swal.fire({
            title: "Añadir FREEMINT",
            html: msg,
            icon: "info",
            showConfirmButton: true,
            showCloseButton: true,
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            showCancelButton: true,
        }).then((result) => {
            if(result.isConfirmed){
                if(isBlockchain) {
                    handleFreeMint();
                } else {
                    handleAddFreeMintBD();
                }
            }
        });
    }

    const handleClearApprove = () => {
        Swal.fire({
            title: "LIMPIAR APROBACIÓN",
            html: "Si continuas vas a <b>LIMPIAR</b> a <b>" + walletUser + "</b><br/><br/> <b>¿Estás seguro?</b>",
            icon: "info",
            showConfirmButton: true,
            showCloseButton: true,
            showDenyButton: true,
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            confirmButtonText: "MYSTERY CAPSULE",
            denyButtonText: 'DECOLLECTION',
        }).then((result) => {
            if(result.isConfirmed){
                handleRemoveApprove(true);
            } else if(result.isDenied) {
                handleRemoveApprove(false);
            }
        });
    }

    const handleApprove = () => {
        Swal.fire({
            title: "AÑADIR APROBACIÓN",
            html: "Si continuas vas a <b>PERMITIR</b> a <b>" + walletUser + "</b> utilizar <b>la función con ID " + cantityInput + "</b><br/><br/> <b>¿Estás seguro?</b>",
            icon: "info",
            showConfirmButton: true,
            showCloseButton: true,
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            showDenyButton: true,
            denyButtonText: 'DECollection',
            confirmButtonText: "Mystery Capsule",
        }).then((result) => {
            if(result.isConfirmed){
                handleAddApprove(true);
            } else if(result.isDenied) {
                console.log("Denied");
                handleAddApprove(false);
            }
        });
    }

    // Transacción completada
    const successAlert = (txId) => {
        Swal.fire({
            title: "Transaction completed!",
            html:
                'The transaction has been confirmed. <br/>'+
                '<div class="alert alert-primary" role="alert">Transaction Hash<br/><br/><b>' + txId + '</b></div><br/><br/>' + 
                '<a class="btn btn-primary" target="_blank" href="'+ ConstantesAPI().NETWORK_SCANTX + txId + '">View on Block Explorer</a>',
            icon: "success",
            showCloseButton: true,
            showCancelButton: false,
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            showConfirmButton: false
        });
    }

    // Transacción completada
    const roleMes = (admin, minter, withdraw, whitelist) => {
        var mensaje = "";
        if(admin) mensaje = mensaje + "Admin "
        if(minter) mensaje = mensaje + "Minter "
        if(withdraw) mensaje = mensaje + "Withdrawer "
        if(whitelist) mensaje = mensaje + "Whitelist "
        Swal.fire({
            title: "¡Ya los hemos encontrado!",
            html:
                'Estos son los roles que tiene asignados la wallet: <br/><br/>'+
                '<div class="alert alert-primary" role="alert"><b>' + mensaje + '</b></div>',
            icon: "success",
            showCloseButton: true,
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            showCancelButton: false,
            showConfirmButton: false
        });
    }

    // Error en la transacción
    const errorAlert = (error) => {
        var mensaje = null;
        if(error.includes("User denied transaction signature")){
            mensaje = "User denied transaction signature";
        } else if(error.includes("must have minter role to mint")){
            mensaje = "You are not on the Whitelist. You can't buy.";
        } else if(error.includes("You have not been approved to run this function")) {
            mensaje = "You have not been approved to run this function.";
        } else {
            mensaje = error;
        }
        Swal.fire({
            title: "Transaction failed!",
            html: '<b>' + mensaje +'</b>',
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            showCancelButton: true,
            cancelButtonText: 'Close',
        });
    }

    // --------------------------------------------
    
    return (
        <Fragment>
            <div className={classes.centrador}>
                <h1 className="text-white fw-bolder display-1">ADMIN ZONE</h1>
                <div className="w-75 mx-auto">
                <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(0)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> ROLES OPTIONS</span>
                </button>
                <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(1)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> WHITELIST/MINT OPTIONS</span>
                </button>
                
                <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(3)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> LOGS</span>
                </button>
                
                { roles.isOwner &&
                <Fragment>
                    <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(4)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> DEPLOY</span>
                    </button>

                    <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(2)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> MYSTERY CAPSULE OPTIONS</span>
                    </button>

                    <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(5)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> COLLECTION OPTIONS</span>
                    </button>

                    <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(6)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> FREE MINTs</span>
                    </button>

                    <button type="button" className="btn btn-outline-light p-4 mb-4 w-25" onClick={(e) => handleOptions(7)}>
                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder"> APPROVE OWNERs</span>
                    </button>
                    
                </Fragment>
                }
                </div>
                { (roles.isOwner && seeOptions === 0) &&
                <Fragment>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">ADD ROLES</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted fw-bold">¡BE CAREFUL!</CardB.Subtitle>
                            <select onChange = { actualizaRol } className="form-control btn w-75 mb-3 btn-outline-light btn-lg text-center mx-auto" id="RolChange">
                                <option>Select role</option>
                                <option>Admin</option>
                                <option>Minter</option>
                                <option>Burner</option>
                                <option>canWhitelist</option>
                            </select>
                            <CardB.Text>
                                <input
                                    className="w-75" placeholder="Wallet" 
                                    type="text" name="wallet" 
                                    onChange = { actualizaWallet }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmRoleAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>ADD NOW</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">SEARCH ROLES</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted fw-bold">Busca los roles de una wallet</CardB.Subtitle>
                            <CardB.Text>
                                <input
                                    className="w-75" placeholder="Wallet" 
                                    type="text" name="wallet" 
                                    onChange = { actualizaWallet }
                                />
                            </CardB.Text>
                            <button
                                onClick = { searchRoles }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>SEARCH NOW</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">DELETE ROLES</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted fw-bold">¡BE CAREFUL!</CardB.Subtitle>
                            <select onChange = { actualizaRol } className="form-control btn w-75 mb-3 btn-outline-light btn-lg text-center mx-auto" id="RolChange">
                                <option>Select role</option>
                                <option>Admin</option>
                                <option>Minter</option>
                                <option>Burner</option>
                                <option>canWhitelist</option>
                            </select>
                            <CardB.Text>
                                <input
                                    className="w-75" placeholder="Wallet" 
                                    type="text" name="wallet" 
                                    onChange = { actualizaWallet }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmDelRolAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>REMOVE NOW</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                </Fragment>
                }
                {((roles.isOwner || roles.canWhitelist) && seeOptions === 1) &&
                <Fragment>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">ADD WHITELIST</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">Set whitelist with default mint amount<br/></CardB.Subtitle>
                            <CardB.Text>
                                <input 
                                    className="w-75" type="text"
                                    placeholder="Wallet" name="wallet"
                                    onChange = { actualizaWallet }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmWLAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>ADD NOW</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">ADD BULK WHITELIST</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">Set whitelist with default mint amount</CardB.Subtitle>
                            <CardB.Text>
                                <textarea 
                                    className="w-75" type="text"
                                    placeholder="Wallet" name="wallet"
                                    onChange = { actualizaBulkWallet }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmBulkWLAlert }
                                disabled={ !props.canConnectToContract }
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>ADD NOW</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>

                </Fragment>
                }
                {((roles.isOwner || roles.isMinter) && seeOptions === 1) &&
                <Fragment>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">MINT ONE</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">AUTO MINT ONE MYSTERY CAPSULE</CardB.Subtitle>
                            <CardB.Text>
                                <input
                                    className="w-75" type="text"
                                    placeholder="Wallet" name="wallet"
                                    onChange = { actualizaWallet }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmMCAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>MINT TO USER NOW</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">MINT CANTITY</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">Mint specific amount</CardB.Subtitle>
                            <CardB.Text>
                                <input className="w-75 mb-3" type="text"
                                    placeholder="Wallet" name="wallet"
                                    onChange = { actualizaWallet }
                                />
                                <input
                                    className="w-75" type="text"
                                    placeholder="Cantidad" name="cantity"
                                    onChange = { actualizaCantidad }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmMCAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>MINT TO USER NOW</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                </Fragment>
                }
                {(roles.isOwner && seeOptions === 2) &&
                <Fragment>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">CHANGE PRINCE</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">CAMBIAR PRECIO DE LA MYSTERY CAPSULE</CardB.Subtitle>
                            <CardB.Text>
                                <input className="w-75" type="text"
                                    placeholder="Cantidad" name="cantity"
                                    onChange = { actualizaCantidad }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmMCPriceAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>CAMBIAR AHORA</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">CHANGE MC - WALLET</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">CAMBIAR MC QUE PUEDE MINTEAR UNA WALLET</CardB.Subtitle>
                            <CardB.Text>
                                <input className="w-75" type="text"
                                    placeholder="Cantidad" name="cantity" 
                                    onChange = { actualizaCantidad }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmMCWalletAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>CAMBIAR AHORA</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">CHANGE MC - TOTAL</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">CAMBIAR MC TOTAL QUE SE PUEDE MINTEAR</CardB.Subtitle>
                            <CardB.Text>
                                <input className="w-75" type="text"
                                    placeholder="Cantidad" name="cantity"
                                    onChange = { actualizaCantidad }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmMCTotalAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>CAMBIAR AHORA</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                
                </Fragment>
                }
                {(roles.isOwner  && seeOptions === 2) &&
                <Fragment>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">RETIRAR MATIC</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">RETIRAR LOS FONDOS DEL SMART CONTRACT<br/><b>Actualmente hay { balanceSC } MATIC</b></CardB.Subtitle>
                            <CardB.Text>
                                <input className="w-75" type="text"
                                placeholder="Cantidad" name="cantity"
                                onChange = { actualizaCantidad }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmWithdrawAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-warning btn-lg w-75">
                                <b>RETIRAR FONDOS</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">RETIRAR USDC</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">RETIRAR LOS FONDOS DEL SMART CONTRACT<br/><b>Actualmente hay { balanceUsdSC } USDC</b></CardB.Subtitle>
                            <CardB.Text>
                                <input className="w-75" type="text"
                                placeholder="Cantidad" name="cantity"
                                onChange = { actualizaCantidad }
                                />
                            </CardB.Text>
                            <button
                                onClick = { confirmWithdrawUsdcAlert }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-warning btn-lg w-75">
                                <b>RETIRAR FONDOS</b>
                            </button>
                        </CardB.Body>
                    </CardB>
                </Card>
                </Fragment>
                }
                {(roles.isOwner && seeOptions === 2) &&
                <Fragment>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">ON/OFF</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">APAGADO O ENCENDIDO DEL SMART CONTRACT<br/><b>¡TEN CUIDADO!</b></CardB.Subtitle>
                            <CardB.Text>Actualmente está { MysteryCapsuleDisabled ? "suspendido" : "activado"} </CardB.Text>
                            { MysteryCapsuleDisabled ? 
                            <button
                                onClick = { confirmSuspendEnable }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-success btn-lg w-75">
                                <b>ACTIVAR</b>
                            </button>
                            :
                            <button
                                onClick = { confirmSuspendEnable }
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-danger btn-lg w-75">
                                <b>DESACTIVAR</b>
                            </button>
                            }
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">ON/OFF</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">APAGADO O ENCENDIDO DE LA PUBLIC SALE<br/><b>¡TEN CUIDADO!</b></CardB.Subtitle>
                            <CardB.Text>Actualmente hay { !isPublicSale ? "VENTA PRIVADA" : "VENTA PUBLICA"} </CardB.Text>
                            { !isPublicSale ? 
                            <button
                                onClick = {confirmPublicsaleEnable}
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-success btn-lg w-75">
                                <b>ACTIVAR - PUBLICO</b>
                            </button>
                            :
                            <button
                                onClick = {confirmPublicsaleEnable}
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-danger btn-lg w-75">
                                <b>DESACTIVAR - PRIVADO</b>
                            </button>
                            }
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdmin}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">ON/OFF</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">APAGADO O ENCENDIDO DE LA FUNCION WHITELIST<br/><b>¡SIRVE PARA AÑADIR O QUITAR GENTE DE LA WL Y DEJAR EL SC SUSPENDIDO!</b></CardB.Subtitle>
                            <CardB.Text>Actualmente está { !functionWhitelist ? " ACTIVADA LAS FUNCIONES DE WL" : " SUSPENDIDAS LAS FUNCIONES DE WL"} </CardB.Text>
                            { functionWhitelist ? 
                            <button
                                onClick = {confirmWhitelistEnable}
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-success btn-lg w-75">
                                <b>ACTIVAR</b>
                            </button>
                            :
                            <button
                                onClick = {confirmWhitelistEnable}
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-danger btn-lg w-75">
                                <b>DESACTIVAR</b>
                            </button>
                            }
                        </CardB.Body>
                    </CardB>
                </Card>
                </Fragment>
                }
                {((roles.isOwner || roles.isAdmin) && seeOptions === 3) && 
                <Fragment>
                <Card className={classes.infoAdminTransactions}>
                    <CardB bg="dark">
                        <CardB.Body>
                            <CardB.Title className="fw-bold display-6">User transactions</CardB.Title>
                            <CardB.Subtitle className="mb-4 text-muted">Load all transations</CardB.Subtitle>
                            <CardB.Text>
                            { allTransactions ? 
                                <JsonToTable className="jsonTable" json={allTransactions} />
                            :
                                <button
                                    onClick={getTransactions}
                                    disabled={!props.canConnectToContract}
                                    type="button"
                                    className="btn btn-outline-light btn-lg w-75">
                                    <b>CARGAR TRANSACCIONES</b>
                                </button>
                            }
                            </CardB.Text>
                        </CardB.Body>
                    </CardB>
                </Card>
                <Card className={classes.infoAdminTransactions}>
                <CardB bg="dark">
                    <CardB.Body>
                        <CardB.Title className="fw-bold display-6">Admin transactions</CardB.Title>
                        <CardB.Subtitle className="mb-4 text-muted">Load all admin transations</CardB.Subtitle>
                        <CardB.Text>
                        { allAdminTransactions ? 
                            <JsonToTable className="jsonTable" json={allAdminTransactions} />
                        :
                            <button
                                onClick={getAdminTransactions}
                                disabled={!props.canConnectToContract}
                                type="button"
                                className="btn btn-outline-light btn-lg w-75">
                                <b>CARGAR TRANSACCIONES</b>
                            </button>
                        }
                        </CardB.Text>
                    </CardB.Body>
                </CardB>
                </Card>
                </Fragment>
                }
                {( roles.isOwner && seeOptions === 4 ) && 
                <Fragment>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">ADD SUPPLY</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Load all cards on blockchain</CardB.Subtitle>
                                <CardB.Text>
                                    <button
                                        onClick={addSupplyOnSC}
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>ADD SUPPLY</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                </Fragment>
                }
                {(roles.isOwner && seeOptions === 5) &&
                <Fragment>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">ADD BULK SUPPLY</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Add new supply</CardB.Subtitle>
                                <CardB.Text>
                                    <input className="w-75" type="text"
                                    placeholder="Array[] - ID General carta" name="cantity"
                                    />
                                    <input className="w-75" type="text"
                                    placeholder="Array[] - Tipo de NFT" name="cantity"
                                    />
                                    <input className="w-75" type="text"
                                    placeholder="Array[] - Supply de cada uno" name="cantity"
                                    />
                                    <button
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>ADD SUPPLY</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">ADMIN MINT</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Mint cards</CardB.Subtitle>
                                <CardB.Text>
                                    <input className="w-75" type="text"
                                    placeholder="Wallet" name="cantity"
                                    />
                                    <input className="w-75" type="text"
                                    placeholder="Array[] - ID NFT" name="cantity"
                                    />
                                    <button
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>MINT NOW</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">ADD REWARD</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Add new reward</CardB.Subtitle>
                                <CardB.Text>
                                    <input className="w-75" type="text"
                                    placeholder="Array[] - ID General de cartas que necesitan" name="cantity"
                                    />
                                    <input className="w-75" type="text"
                                    placeholder="Array[] - Tipo de NFT de recompensa" name="cantity"
                                    />
                                    <input className="w-75" type="text"
                                    placeholder="Limite de rewards" name="cantity"
                                    />
                                    <input className="w-75" type="text"
                                    placeholder="Bool - Activo(true), desactivado(false)" name="cantity"
                                    />
                                    <button
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>ADD REWARD</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">ON/OFF Reward</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Now is UNDEFINED</CardB.Subtitle>
                                <CardB.Text>
                                    <button
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>MINT NOW</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">CHANGE LIMIT REWARD</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Change the limit of this reward</CardB.Subtitle>
                                <CardB.Text>
                                    <button
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>MINT NOW</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">SEE REWARD</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Info about this reward</CardB.Subtitle>
                                <CardB.Text>
                                    <button
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>MINT NOW</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                    <Card className={classes.infoAdmin}>
                        <CardB bg="dark">
                            <CardB.Body>
                                <CardB.Title className="fw-bold display-6">ON/OFF SC</CardB.Title>
                                <CardB.Subtitle className="mb-4 text-muted">Now is UNDEFINED</CardB.Subtitle>
                                <CardB.Text>
                                    <button
                                        disabled={!props.canConnectToContract}
                                        type="button"
                                        className="btn btn-outline-light btn-lg w-50">
                                        <b>MINT NOW</b>
                                    </button>
                                </CardB.Text>
                            </CardB.Body>
                        </CardB>
                    </Card>
                </Fragment>
                }
                {(roles.isOwner && seeOptions === 6) &&
                    <Fragment>
                        <Card className={classes.infoAdmin}>
                            <CardB bg="dark">
                                <CardB.Body>
                                    <CardB.Title className="fw-bold display-6">ADD BULK FREE MINT</CardB.Title>
                                    <CardB.Subtitle className="mb-4 text-muted">Mystery Capsule <br/><b>(BLOCKCHAIN)</b></CardB.Subtitle>
                                    <CardB.Text>
                                        <textarea className="w-75 mb-3" type="text"
                                            placeholder="Wallets" name="wallet"
                                            onChange = { actualizaBulkWallet }
                                        />
                                        <textarea
                                            className="w-75" type="text"
                                            placeholder="Cantidades" name="cantity"
                                            onChange = { actualizaBulkCantidad }
                                        />
                                        <br/><br/>
                                        <button
                                            disabled={!props.canConnectToContract}
                                            onClick={(e) => freeMintAlert(true)}
                                            type="button"
                                            className="btn btn-outline-light btn-lg w-50">
                                            <b>ADD NOW</b>
                                            
                                        </button>
                                    </CardB.Text>
                                </CardB.Body>
                            </CardB>
                        </Card>
                        <Card className={classes.infoAdmin}>
                            <CardB bg="dark">
                                <CardB.Body>
                                    <CardB.Title className="fw-bold display-6">ADD FREE MINT</CardB.Title>
                                    <CardB.Subtitle className="mb-4 text-muted">DECollection <br/><b>(BASE DE DATOS)</b></CardB.Subtitle>
                                    <CardB.Text>
                                        <input className="w-75 mb-3" type="text"
                                            placeholder="Wallet" name="wallet"
                                            onChange = { actualizaWallet }
                                        />
                                        <input
                                            className="w-75" type="text"
                                            placeholder="ID NFT" name="cantity"
                                            onChange = { actualizaCantidad }
                                        />
                                        <br/><br/>
                                        <button
                                            disabled={!props.canConnectToContract}
                                            type="button"
                                            onClick={(e) => freeMintAlert(false)}
                                            className="btn btn-outline-light btn-lg w-50">
                                            <b>ADD NOW</b>
                                        </button>
                                    </CardB.Text>
                                </CardB.Body>
                            </CardB>
                        </Card>
                    </Fragment>
                }
                {(roles.isOwner && seeOptions === 7) &&
                    <Fragment>
                        <Card className={classes.infoAdmin}>
                            <CardB bg="dark">
                                <CardB.Body>
                                    <CardB.Title className="fw-bold display-6">SEND APPROVE</CardB.Title>
                                    <CardB.Subtitle className="mb-4 text-muted">Crea una aprobación para otro owner.</CardB.Subtitle>
                                    <CardB.Text>
                                        <textarea className="w-75 mb-3" type="text"
                                            placeholder="Wallet" name="wallet"
                                            onChange = { actualizaWallet }
                                        />
                                        <textarea
                                            className="w-75" type="text"
                                            placeholder="ID función" name="cantity"
                                            onChange = { actualizaCantidad }
                                        />
                                        <br/><br/>
                                        <button
                                            disabled={!props.canConnectToContract}
                                            onClick={(e) => handleApprove()}
                                            type="button"
                                            className="btn btn-outline-light btn-lg w-50">
                                            <b>ADD NOW</b>
                                            
                                        </button>
                                    </CardB.Text>
                                </CardB.Body>
                            </CardB>
                        </Card>
                        <Card className={classes.infoAdmin}>
                            <CardB bg="dark">
                                <CardB.Body>
                                    <CardB.Title className="fw-bold display-6">CLEAN APPROVE</CardB.Title>
                                    <CardB.Subtitle className="mb-4 text-muted">Limpiar las aprobaciones (Tuyas o las que diste)</CardB.Subtitle>
                                    <CardB.Text>
                                        <input className="w-75 mb-3" type="text"
                                            placeholder="Wallet" name="wallet"
                                            onChange = { actualizaWallet }
                                        />
                                        <input
                                            className="w-75" type="text"
                                            placeholder="ID función" name="cantity"
                                            onChange = { actualizaCantidad }
                                        />
                                        <br/><br/>
                                        <button
                                            disabled={!props.canConnectToContract}
                                            type="button"
                                            onClick={(e) => handleClearApprove()}
                                            className="btn btn-outline-light btn-lg w-50">
                                            <b>ADD NOW</b>
                                        </button>
                                    </CardB.Text>
                                </CardB.Body>
                            </CardB>
                        </Card>
                    </Fragment>
                }
            </div>
        </Fragment>
    );

}

// ----------------------------------------------
// AÑADIR TRANSACCIONES A BASE DE DATOS
// ----------------------------------------------

const manejarEnvioTransaccion = async (cuenta, tx, tipo, accion) => {

    // Codificar nuestros datos como JSON
    const datos = {
        wallet: cuenta,
        tx: tx,
        type: tipo,
        action: accion
    }

    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    await fetch(`${ConstantesAPI().RUTA_API}/guardar_AdminTx.php`, {
        method: "POST",
        body: cargaUtil,
    });
    //const exitoso = await respuesta.json();
}

const saveUsedSupply = async () => {

    await fetch(`${ConstantesAPI().RUTA_API}/saveUseSupply.php`, {
        method: "POST",
    });

}


export default Admin;