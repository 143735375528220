export var GlobalInfoAccount = {
    account: null,
    isConnected: false,
    balance: 0,
    balanceUSDC: 0,
    mintDetails: {
      isPublicSale: false,
      isWhitelisted: false,
      mintableChest: 0,
      userMinted: 0,
      maxMintAmount: 0,
    },
    genesisCapsDetails: {
      totalCaps: 0,
      capsIds: [],
      burnedCaps: []
    },
    collectionDetails: {
      totalCards: 0,
      cardsIds: [],
    },
    roles: {
      isOwner: false,
      canWhitelist: false,
      isAdmin: false,
      isMinter: false,
    }
  };

export default GlobalInfoAccount;
  
export const setGlobalInfoAccount = (newGlobalVars) => {
    GlobalInfoAccount = newGlobalVars;
}