import { Fragment } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import classes from "./DropdownWallet.module.css";

const DropdownWallet = (props) => {

    const account = (props.currentAccount).substr(0,5)+"....."+(props.currentAccount).substr(38,43);
    const balance = Number(props.balance).toFixed(4);
    const balanceUSDC = Number(props.balanceUSDC).toFixed(2);

    const isMumbai = props.chainId === 80001;
    const chainVariant = isMumbai ? "warning" : "light";
    const titleDropdown = isMumbai ? account.concat(" (MUMBAI)") : account;

    return (
      <Fragment>
        <DropdownButton id="dropdown-basic-button" title={titleDropdown} variant={chainVariant}>
            <Dropdown.Item disabled className={classes.dropMenu}><b>{balance} MATIC</b></Dropdown.Item>
            <Dropdown.Item disabled className={classes.dropMenu}><b>{balanceUSDC} USDC</b></Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className={classes.dropMenu} onClick = {props.onLogout} >Disconnect</Dropdown.Item>
        </DropdownButton>
      </Fragment>
    );
}

export default DropdownWallet;