import { Fragment } from "react";
//import ReactLoading from "react-loading";
import CenterLogo from "../../Elements/CenterLogo/CenterLogo";
import classes from "./Loader.module.css";

const Loader = props => {

    const isMobile = props.isMobile;

    return (
        <Fragment>
            <div className={classes.loaderDiv}>
                <CenterLogo/>
                <div className="container-fluid d-flex col-sm">
                    <div className={classes.moon}>
                        <img src="https://cdn2.iconfinder.com/data/icons/thesquid-ink-40-free-flat-icon-pack/64/space-rocket-512.png" alt="Leira" className={classes.loader}/>
                        <ul className={classes.ulstyle}>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                        </ul>
                    </div>
                </div>
                <h3 className="mt-3 display-3 fw-bold text-center">Landing on Leira...</h3>
                { isMobile && <h3 className="display-3 fixed-bottom text-center fw-bold">Not avaible on Mobile</h3> }
            </div>
            
        </Fragment>
    );
    
}

/*
<ReactLoading
                type="balls"
                color="white"
                className={classes.centrador}
            />

*/

export default Loader;